<template>
  <div>
    <b-row>
      <b-col md="6">
        <b-list-group
          v-if="data"
          flush
        >
          <b-list-group-item>Nom: <b>{{ data.name }}</b></b-list-group-item>
          <b-list-group-item>Adresse de géolocalisation: <b>{{ data.address }}</b></b-list-group-item>
          <b-list-group-item>Capacité: <b>{{ data.capacity }}</b></b-list-group-item>

          <b-list-group-item>Date de création:
            <b>{{
              this.$coddyger.cdgDateFormat(data.createdAt, true)
            }}</b></b-list-group-item>
          <b-list-group-item>Dernière mis à jour:
            <b>{{
              this.$coddyger.cdgDateFormat(data.updatedAt, true)
            }}</b></b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col md="6">
        <b-row>
          <b-col lg="12">
            <b-list-group
              v-if="data"
              flush
            >
              <b-list-group-item>Type d'ouvrage: <b>{{ data.categoryName }}</b></b-list-group-item>
              <b-list-group-item>Unité de production: <b>{{ data.up }}</b></b-list-group-item>
              <b-list-group-item>Administrateur: <b>{{ data.user }}</b></b-list-group-item>
            </b-list-group>
          </b-col>
          <b-col lg="12">
            <b-list-group
              v-if="data.profile"
              flush
            >
              <b-list-group-item
                class="text-primary"
                disabled
              >Profile <b /></b-list-group-item>
              <b-list-group-item>Profile: <b>{{ data.profile.title }}</b></b-list-group-item>
              <b-list-group-item
                class="text-primary"
                disabled
              >Abilitation(s) <b /></b-list-group-item>
              <b-list-group-item
                v-for="(item, index) in data.profile.ability"
                :key="index"
              >
                #<b>{{ index + 1 }} - </b>
                Module: <b>{{ (item.subject === 'all' ? 'Tous' : item.subject) }}</b>
                Droit: <b>{{ (item.action === 'manage' ? 'Tous' : item.action) }}</b>
              </b-list-group-item>
            </b-list-group>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BLink,
  BTooltip,
  BCardText,
  BFormInput,
  BFormGroup,
  BFormFile,
  BSpinner,
  BListGroup,
  BListGroupItem,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BLink,
    BTooltip,
    BCardText,
    BFormInput,
    BFormGroup,
    BFormFile,
    BSpinner,
    BListGroup,
    BListGroupItem,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
  },
  setup() {
    const postStatusVariantAndIcon = requestStat => {
      if (requestStat === 'pending') return { variant: 'warning', icon: 'PieChartIcon', text: 'En attente' }
      if (requestStat === 'active') return { variant: 'success', icon: 'CheckCircleIcon', text: 'Active' }
      if (requestStat === 'archived') return { variant: 'danger', icon: 'XIcon', text: 'Désactivé' }
      if (requestStat === 'ended') return { variant: 'info', icon: 'CheckCircleIcon', text: 'Terminé' }
      return { variant: 'primary', icon: 'XIcon' }
    }

    return {
      postStatusVariantAndIcon,
    }
  },
})
</script>
